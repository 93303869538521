import request from '@/api/request.js'
// 登录
export function Login (data) {
  return request.post('/adm/login', data)
}
// 学校首页-学校统计
export function Schoolhome (data) {
  return request.post('/school/statistics')
}
// 学校首页排名
export function Schoolranking (data) {
  return request.post('/school/classList', data)
}
// 学校班级列表
export function SchoolClassList (data) {
  return request.post('/school/isClassList', data)
}
// 学校年级信息
export function classTypeInfo (data) {
  return request.post('/school/classTypeInfo')
}
// 学校创建班级
export function SchoolclassAdd (data) {
  return request.post('/school/classAdd', data)
}
// 学校老师列表
export function teacherList (data) {
  return request.post('/school/teacherList', data)
}
// 学校添加老师
export function teacherAdd (data) {
  return request.post('/school/teacherAdd', data)
}
// 学校班级修改
export function editClass (data) {
  return request.post('/school/editClass', data)
}
// 学校学生申请列表
export function stuapply (data) {
  return request.post('/school/stuapply', data)
}
// 学校学生申请处理
export function stuapplycheck (data) {
  return request.post('/teacher/studentApplyEdit', data)
}
// 学校修改密码
export function modifyPass (data) {
  return request.post('/school/modifyPass', data)
}
// 学校创建年级
export function typeAdd (data) {
  return request.post('school/typeAdd', data)
}
// 学校设置到离校时段
export function setArriveLeaveTime (data) {
  return request.post('school/setArriveLeaveTime', data)
}
// 学校到离校时段信息
export function arriveLeaveTime (data) {
  return request.post('school/arriveLeaveTime', data)
}
// 学校教职工在校时间设置
export function staffWorkTime (data) {
  return request.post('school/staffWorkTime', data)
}
// 学校教职工出入信息列表
export function arriveStaffList (data) {
  return request.post('school/arriveStaffList', data)
}
// 教职工出入信息列表 - 单日数据
export function arriveStaffListOfDate (data) {
  return request.post('school/arriveStaffListOfDate', data)
}
// 教职工出入信息详情 - 单日单人数据
export function arriveInfoByUserid (data) {
  return request.post('school/arriveInfoByUserid', data)
}
// 学校到校离校汇总
export function sclArriveCount (data) {
  return request.post('school/sclArriveCount', data)
}
// 年级列表
export function gradeList (data) {
  return request.post('school/gradeList', data)
}
// 班级列表
export function chooseClassList (data) {
  return request.post('school/chooseClassList', data)
}
// 班级到离校记录
export function classArriveList (data) {
  return request.post('school/classArriveList', data)
}
// 学校设置模板获取
export function getSclTpl (data) {
  return request.post('school/getSclTpl', data)
}
// 新转学申请列表
export function newstuapply (data) {
  return request.post('teacher/studentApply', data)
}
// 删除班级
export function classDel (data) {
  return request.post('school/classDel', data)
}
// 开启关闭 班级申请
export function classOpen (data) {
  return request.post('school/classOpen', data)
}
// 修改班级备注名称
export function classRemark (data) {
  return request.post('school/classRemark', data)
}
// 删除教师
export function teaDel (data) {
  return request.post('school/teaDel', data)
}
// 添加教师时候查询
export function addTeacherSearch (data) {
  return request.post('school/addTeacherSearch', data)
}
